import CommonIcon from '@/components/common-icon';
import { useRouter } from '@/core/hooks';
import { LANG } from '@/core/i18n';
import { localStorageApi, LOCAL_KEY, useAppContext, useLoginUser } from '@/core/store';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const GiftBox = dynamic(() => import('./gift-box'), {
  ssr: false,
});
const SidebarBonus = () => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const router = useRouter();
  const { user } = useLoginUser();
  const { isLogin } = useAppContext();
  // 注册时间大于7则不是新用户;返回true表示不是新用户
  const isNotNewUser = (registerTime: number) => {
    const date = dayjs(registerTime);
    const duration = dayjs().diff(date, 'day');
    return duration > 7;
  };
  const savedTimestamp = localStorageApi.getItem(LOCAL_KEY.SIDEBAR_CLOSE_TIME);
  function isPreviousDay() {
    if (!savedTimestamp) {
      return false;
    }
    const savedTime = dayjs(Number(savedTimestamp));
    const now = dayjs();
    // 判断保存的时间是否是前一天
    const isPreviousDay = now.diff(savedTime, 'day') >= 1;
    return isPreviousDay;
  }
  // · 显示情况：未登录显示、登录-注册时间未超过7天显示
  // · 点击关闭：未登录点击关闭-刷新页面显示、登录-注册时间未超过7天点击关闭-次日显示
  const shouldHideSidebar = () => {
    // 未登录情况: 不隐藏
    if (!isLogin) {
      return !isVisible;
    }
    const registerTime = user?.registerTime || 0;
    // 新用户定义: 注册时间未超过7天
    const isNewUser = !isNotNewUser(registerTime);
    // 新用户显示逻辑
    if (isNewUser) {
      // 判断是否是前一天关闭
      if (isPreviousDay()) {
        return false; // 如果是前一天关闭的，则次日显示
      }
      // 此时还没点击关闭
      if (savedTimestamp === null) {
        return false;
      }
      // 否则就是当天关闭
      return !isPreviousDay();
    }
    // 老用户: 注册时间超过7天，则隐藏
    return true;
  };
  if (shouldHideSidebar()) return null;
  const onSidebarBonusClick = () => {
    router.push({
      pathname: '/welfare-center',
      newTab: true,
    });
  };
  const onClose = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
    setIsMouseEnter(false);
    setIsVisible(false);
    if (!isNotNewUser(user?.registerTime || 0)) {
      const currentTimestamp = dayjs().valueOf(); // 获取当前系统时间戳
      localStorageApi.setItem(LOCAL_KEY.SIDEBAR_CLOSE_TIME, currentTimestamp);
    }
  };
  return (
    <>
      <div
        className='sidebar-bonus'
        onClick={onSidebarBonusClick}
        onMouseEnter={() => setIsMouseEnter(true)}
        onMouseLeave={() => setIsMouseEnter(false)}
      >
        <div className='content'>
          <CommonIcon name='common-close-filled' enableSkin size={16} className='close-icon' onClick={onClose} />
          <GiftBox width={148} height={115} />
        </div>
        <div className='claim-btn'>
          {isMouseEnter ? LANG('Get it now') : LANG('New User Reward')}
          {isMouseEnter && <CommonIcon name='common-circle-arrow-right-0' size={16} style={{ marginLeft: 6 }} />}
        </div>
      </div>
      <style jsx>{`
        .sidebar-bonus {
          cursor: pointer;
          position: fixed;
          bottom: 145px;
          right: 12px;
          text-align: center;
          z-index: 999;
          transform: translateZ(0);
          .content {
            min-width: 148px;
            height: 115px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .tips {
              font-size: 12px;
              font-weight: 500;
              text-align: center;
              position: fixed;
              bottom: 200px;
              max-width: 110px;
              color: var(--spec-font-special-brand-color);
            }
            :global(.close-icon) {
              position: absolute;
              right: 14px;
              top: 0;
              z-index: 99;
            }
          }

          .claim-btn {
            background-color: #1772f8;
            color: #fff;
            padding: 8px 20px;
            border-radius: 45px;
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            min-width: 150px;
            bottom: 0px;
          }
        }
      `}</style>
    </>
  );
};
export default SidebarBonus;
